(function ($) {
    "use strict";

    var lfm = function (options, cb) {
        var route_prefix = (options && options.prefix) ? options.prefix : '/laravel-filemanager';
        window.open(route_prefix + '?type=' + options.type || 'file', 'FileManager', 'width=900,height=600');
        window.SetUrl = cb;
    };

    var LFMButton = function (context) {
        var ui = $.summernote.ui;
        var button = ui.button({
            contents: '<i class="note-icon-picture"></i> ',
            tooltip: 'Insert image with filemanager',
            click: function () {

                lfm({type: 'file', prefix: '/laravel-filemanager'}, function (lfmItems, path) {
                    lfmItems.forEach(function (lfmItem) {
                        context.invoke('insertImage', lfmItem.url);
                    });
                });

            }
        });
        return button.render();
    };

    window.makeSummernote = function ($content, cardHeight = null, onChange = undefined) {
        const height = cardHeight ? cardHeight : ($content.attr('data-height') ? $content.attr('data-height') : 300);

        $content.summernote({
            dialogsInBody: true,
            tabsize: 2,
            height: height,
            placeholder: $content.attr('placeholder'),
            fontNames: [],
            emptyPara: "<div></div>",
            prettifyHtml: true,
            codemirror: {
                mode: 'text/html',
                htmlMode: true,
                lineNumbers: true,
                theme: 'monokai'
            },
            callbacks: {
                onChange: onChange,
                onPaste(event) {
                    event.stopPropagation();
                    event.preventDefault();
                    const evt = event.originalEvent

                    let buffer;
                    for (let i = 0, len = evt.clipboardData.types.length; i < len; i++) {

                        buffer = evt.clipboardData.getData(evt.clipboardData.types[i]);
                        console.log(`evt.clipboardData.types`, evt.clipboardData.types)
                        if (evt.clipboardData.types.indexOf('text/html') !== -1) {
                            buffer = evt.clipboardData.getData('text/html');
                            console.log(`buffer text/html`, buffer)
                        } else if (evt.clipboardData.types.indexOf('text/plain') !== -1) {
                            buffer = evt.clipboardData.getData('text/plain');
                            console.log(`buffer text/plain`, buffer)
                        }

                    }
                    const div = $('<div />')
                    div.append(buffer);

                    console.log(`div`, buffer)

                    div.find('*').removeAttr('dir class style id start role');
                    div.find('*').find('a').attr('rel', 'nofollow noopener noreferrer');

                    div.each(function () {
                        $(this).html($(this).html().replace(/&nbsp;/gi, ' '));
                    });

                    setTimeout(function () {
                        document.execCommand('insertHtml', false, div.html());
                    }, 10);

                }
            },
            toolbar: [
                ['style', ['style']],
                ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear',]],
                ['fontname', ['fontname']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],

                ['insert', ['hr','link', 'video', 'lfm']],
                ['view', ['fullscreen', 'codeview', 'help']],

                ['paperSize', ['paperSize']], // The Button
            ],
            popover: {
                image: [
                    ['custom', ['imageAttributes']],
                    ['imagesize', ['imageSize100', 'imageSize50', 'imageSize25']],
                    ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
                    ['float', ['floatLeft', 'floatRight', 'floatNone']],
                    ['remove', ['removeMedia']]
                ],
                link: [
                    ['link', ['linkDialogShow', 'unlink']]
                ],
                air: [
                    ['color', ['color']],
                    ['font', ['bold', 'underline', 'clear']],
                    ['para', ['ul', 'paragraph']],
                    ['table', ['table']],
                    ['insert', ['link', 'picture']]
                ]
            },
            buttons: {
                lfm: LFMButton
            }
        });
    }

})(jQuery);
